import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js'
import { PointLightHelper } from 'three'

//cursor
const cursor = {x: 0, y: 0}
window.addEventListener('mousemove', function(event){
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = - (event.clientY / sizes.height - 0.5)
   
})

// Canvas
const canvas = document.querySelector('canvas.webgl')

//actions
var action, action2, action3, action4, action5, action6, action7, action8
var action11, action12
var action10, action15, action16, action17
var action9, action13
var action14

var forward = []
forward.push(action, action2, action3)

// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

// Scene
const scene = new THREE.Scene()


const loader = new GLTFLoader()

let mixer = null


loader.load(
    '/models/example/glTF-Binary/ScrewsAnimation2.glb', function (gltf)
    {
        scene.add(gltf.scene)
        console.log(gltf)

        mixer = new THREE.AnimationMixer(gltf.scene)

        action = mixer.clipAction(gltf.animations[0])
        action.clampWhenFinished = true
        action.setLoop(THREE.LoopOnce)

        action2 = mixer.clipAction(gltf.animations[1])
        action2.clampWhenFinished = true
        action2.setLoop(THREE.LoopOnce)
        
        action3 = mixer.clipAction(gltf.animations[2])
        action3.clampWhenFinished = true
        action3.setLoop(THREE.LoopOnce)

        action4 = mixer.clipAction(gltf.animations[3])
        action4.clampWhenFinished = true
        action4.setLoop(THREE.LoopOnce)

        action5 = mixer.clipAction(gltf.animations[4])
        action5.clampWhenFinished = true
        action5.setLoop(THREE.LoopOnce)

        action6 = mixer.clipAction(gltf.animations[5])
        action6.clampWhenFinished = true
        action6.setLoop(THREE.LoopOnce)

        action7 = mixer.clipAction(gltf.animations[6])
        action7.clampWhenFinished = true
        action7.setLoop(THREE.LoopOnce)

        action8 = mixer.clipAction(gltf.animations[7])
        action8.clampWhenFinished = true
        action8.setLoop(THREE.LoopOnce)
        
        action9 = mixer.clipAction(gltf.animations[8])
        action9.clampWhenFinished = true
        action9.setLoop(THREE.LoopOnce)
        
        action10 = mixer.clipAction(gltf.animations[9])
        action10.clampWhenFinished = true
        action10.setLoop(THREE.LoopOnce)

        action11 = mixer.clipAction(gltf.animations[10])
        action11.clampWhenFinished = true
        action11.setLoop(THREE.LoopOnce)

        action12 = mixer.clipAction(gltf.animations[11])
        action12.clampWhenFinished = true
        action12.setLoop(THREE.LoopOnce)

        action13 = mixer.clipAction(gltf.animations[12])
        action13.clampWhenFinished = true
        action13.setLoop(THREE.LoopOnce)

        action14 = mixer.clipAction(gltf.animations[13])
        action14.clampWhenFinished = true
        action14.setLoop(THREE.LoopOnce)

        action15 = mixer.clipAction(gltf.animations[14])
        action15.clampWhenFinished = true
        action15.setLoop(THREE.LoopOnce)

        action16 = mixer.clipAction(gltf.animations[15])
        action16.clampWhenFinished = true
        action16.setLoop(THREE.LoopOnce)

        action17 = mixer.clipAction(gltf.animations[16])
        action17.clampWhenFinished = true
        action17.setLoop(THREE.LoopOnce)

    }
    
)

const rectLight = new THREE.RectAreaLight( 0xffffff, 50, 2, 2 );
        rectLight.color.setHSL( 0.1, 1, 0.95 );
        rectLight.position.set( - 1, 1.75, 1 );
        rectLight.lookAt(new THREE.Vector3())
        scene.add( rectLight );

        rectLight.castShadow = true;

const rectLight2 = new THREE.RectAreaLight( 0xffffff, 50, 2, 2 );
        rectLight2.color.setHSL( 0.1, 1, 0.95 );
        rectLight2.position.set( 1 , 1.75, - 1 );
        rectLight2.lookAt(new THREE.Vector3())
        scene.add( rectLight2 );

        rectLight2.castShadow = true;

// const rectLightHelper = new RectAreaLightHelper(rectLight2)
// scene.add(rectLightHelper)

const hemisphereLight = new THREE.HemisphereLight(0x404040, 0xffffff, 5)
hemisphereLight.position.set(0, 20, 0)
scene.add(hemisphereLight)





//raycaster
const raycaster = new THREE.Raycaster()

const rayOrigin = new THREE.Vector3(- 3, 0, 0)
const rayDirection = new THREE.Vector3(10, 0, 0)
rayDirection.normalize()

raycaster.set(rayOrigin, rayDirection)

//mouse
const mouse = new THREE.Vector2()
let currentIntersect = null

window.addEventListener('mousemove', function(event){
    mouse.x = event.clientX / sizes.width * 2 - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1
})

let section = 0
var sectionReverse = 5
var active = true

canvas.addEventListener('click', function(){
    closeInfo()
})


function closeInfo(){
    var visibleDiv = document.getElementById("popup")
    
        visibleDiv.style.display = "none"
}


window.addEventListener('mouseup', function(){

    if(currentIntersect === 1 && section <= 4)
    {
        section ++

            if(section === 1){
                Animation1()
                // console.log(action2)
                // console.log(action)
                    if(active === false){
                        reset()
                    }
            }

            if(section === 2){
                Animation2()
                    if(active === false){
                        reset2()
                    }
            }
            if(section === 3){
                Animation3()
                    if(active === false){
                        reset3()
                    }
            }
            if(section === 4){
                Animation4()
                    if(active === false){
                        reset4()
                    }
            }
            if(section === 5){
                Animation5()
                    if(active === false){
                        reset5()
                    }
                active === true
                sectionReverse = 5
            }
    }
   
    

    //reverse
    else if(currentIntersect === 1 && section >= 5 ){
        sectionReverse --
        // active = true
        // console.log(sectionReverse)
        
            if (sectionReverse === 4){
                Return1()
            }
            if (sectionReverse === 3){
                Return2()
            }
            if (sectionReverse === 2){
                Return3()
            }
            if (sectionReverse === 1){
                Return4()
            }
            if (sectionReverse === 0){
                Return5()
                // console.log('section5')
                // console.log(action2)
                active = false
            }
    }

    // console.log(section)
    // console.log(sectionReverse)
    
    // if(section === 2){
    //     action2.play()
    // }

    // if(active === false){
    //     reset()
    //     // reset2()
    // }

})


function Animation1(){
    action.play()
    action3.play()
    action4.play()
    action7.play()
    action2.play()
    action5.play()
    action6.play()
    action8.play()
}

function Animation2(){
    action11.play()
    action12.play()
}

function Animation3(){
    action10.play()
    action15.play()
    action16.play()
    action17.play()
}

function Animation4(){
    action9.play()
    action13.play()
    // sectionReverse = 5
}

function Animation5(){
    action14.play()
    // console.log(sectionReverse)
}

function Return1(){
    action14.timeScale = -1
    action14.paused = false
    // console.log(sectionReverse)
}
function Return2(){
    action9.timeScale = -1
    action9.paused = false
    action13.timeScale = -1
    action13.paused = false
}

function Return3(){
    action10.timeScale = -1
    action10.paused = false
    action15.timeScale = -1
    action15.paused = false
    action16.timeScale = -1
    action16.paused = false
    action17.timeScale = -1
    action17.paused = false
}

function Return4(){
    action11.timeScale = -1
    action11.paused = false
    action12.timeScale = -1
    action12.paused = false
}

function Return5(){
    action.timeScale = -1
    action.paused = false
    action2.timeScale = -1
    action2.paused = false
    action3.timeScale = -1
    action3.paused = false
    action4.timeScale = -1
    action4.paused = false
    action5.timeScale = -1
    action5.paused = false
    action6.timeScale = -1
    action6.paused = false
    action7.timeScale = -1
    action7.paused = false
    action8.timeScale = -1
    action8.paused = false
    section = 0
}

function reset(){
    action.timeScale = 1
    action.paused = false
    action3.timeScale = 1
    action3.paused = false
    action4.timeScale = 1
    action4.paused = false
    action7.timeScale = 1
    action7.paused = false
    action2.timeScale = 1
    action2.paused = false
    action8.timeScale = 1
    action8.paused = false
    action6.timeScale = 1
    action6.paused = false
    action5.timeScale = 1
    action5.paused = false
}
function reset2(){
    action11.timeScale = 1
    action11.paused = false
    action12.timeScale = 1
    action12.paused = false
}

function reset3(){
    action10.timeScale = 1
    action10.paused = false
    action15.timeScale = 1
    action15.paused = false
    action16.timeScale = 1
    action16.paused = false
    action17.timeScale = 1
    action17.paused = false
}

function reset4(){
    action9.timeScale = 1
    action9.paused = false
    action13.timeScale = 1
    action13.paused = false
}

function reset5(){
    action14.timeScale = 1
    action14.paused = false
}

//textures
// const textureLoader = new THREE.TextureLoader()
// const metalColorTexture = textureLoader.load('models/textures/BrushedMetalColor.jpg')
// const metalRoughnessTexture = textureLoader.load('models/textures/BrushedMetalRoughness.jpg')
// const metalMetalnessTexture = textureLoader.load('models/textures/BrushedMetalMetallic.jpg')
// const metalNormalTexture = textureLoader.load('models/textures/BrushedMetalNormal.jpg')

// const material = new THREE.MeshStandardMaterial({
//     map: metalColorTexture,
//     roughnessMap: metalRoughnessTexture,
//     metalnessMap: metalMetalnessTexture,
//     normalMap: metalNormalTexture
// })


const light = new THREE.PointLight(0xffffff, 1, 100,)
light.position.y = 5
scene.add(light)


const cube = new THREE.Mesh(
    new THREE.BoxBufferGeometry(3, 0.6, 1),
    new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 0
    })
)

scene.add(cube)
cube.position.set(1, -0.2, 0)

console.log()

// Camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)

camera.position.set(-1.5, 1.5, 2)
camera.lookAt(light.position)
scene.add(camera)

//controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setClearColor(0x000000, 0)

// Animate
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //raycaster
    raycaster.setFromCamera(mouse, camera)
    const object = [cube]
    const intersects = raycaster.intersectObjects(object)

    if(intersects.length){
        if(currentIntersect === null){
            console.log('enter')
            document.body.style.cursor = 'pointer'
        }
        
        currentIntersect = 1
    }
    else{
        if(currentIntersect)
        {
            console.log('mouse leave')
        }
        document.body.style.cursor = 'default'
        currentIntersect = null
    }

    // if(intersects.length)

    if(mixer)
    {
        mixer.update(deltaTime)
    }

    //update controls
    controls.update()

    // Render
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
}

tick()